import React from 'react'
import { theme } from '@damen/ui'

import { ResultOf } from '@graphql-typed-document-node/core'
import { PrimaryHeader, SecondaryHeader } from '@/components/UI'

import { useBreadcrumb } from '@/hooks'
import { GetAboutPageDocument } from '@/queries/queries/About/getAboutPage.generated'
import VideoHeader from '@/components/UI/VideoHeader'
import { BlokZone } from '@/components/UI/BlokZone'
import Components from '@/components/Components'

// TODO: Move to AboutContent when All Components are configured correctly
type Content = ResultOf<typeof GetAboutPageDocument>['aboutPage']['content']

interface Props {
  content: Content
}

const Presentational: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { body, header } = content
  // TODO: FIx typing
  const anyHeader: any = header
  const breadcrumb = useBreadcrumb(
    header?.__typename !== 'HeaderVideo' ? header?.breadcrumb?.title : '',
    header?.__typename !== 'HeaderVideo' ? header?.breadcrumb?.link : null,
    'white',
    header.__typename === 'HeaderSecondaryText' ? theme.colors.marineBlack : theme.colors.white,
  )

  return (
    <>
      <section>
        {header.__typename === 'HeaderSecondaryText' && (
          <SecondaryHeader
            content={{
              id: header.id,
              header: { ...anyHeader, breadcrumb },
            }}
          />
        )}

        {header.__typename === 'HeaderPrimary' && (
          <PrimaryHeader
            content={{
              id: 'header',
              header: { ...anyHeader, breadcrumb },
            }}
          />
        )}

        {header.__typename === 'HeaderVideo' && <VideoHeader content={header as any} />}
      </section>
      <BlokZone bloks={body} componentLookup={Components} />
    </>
  )
}

export default Presentational
