import React from 'react'

import { useQuery } from '@apollo/client'
import Layout from '@/components/layout'
import Presentational from '@/screens/About/Presentational'

import { Screen } from '@/types'
import { GetAboutPageDocument, GetAboutPageQuery } from '@/queries/queries/About/getAboutPage.generated'

const About: React.FC<React.PropsWithChildren<Screen>> = ({ slug }) => {
  const { loading, data } = useQuery<GetAboutPageQuery>(GetAboutPageDocument, {
    variables: {
      id: slug,
    },
  })

  return (
    <Layout.Default
      contactData={data?.modalContact?.content}
      departmentsContactData={data?.modalDepartmentsContact}
      departmentsNewsletterData={data?.modalDepartmentsNewsletter}
      donationData={data?.modalDonation}
      feedbackData={data?.modalFeedback?.content}
      footerData={data?.footer}
      loading={loading}
      newsletterData={data?.modalNewsletter?.content}
      seoData={data?.aboutPage?.content?.seo}
      siteData={data?.site}
    >
      <Presentational content={data?.aboutPage?.content} />
    </Layout.Default>
  )
}

export default About
