import React from 'react'
import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next'
import About from '@/screens/About'
import ContentPage from '@/screens/ContentPage'
import { GetAboutPageDocument } from '@/queries/queries/About/getAboutPage.generated'
import { GetContentPageDocument } from '@/queries/queries/getContentPage.generated'
import { defaultGetStaticPathsFallback, defaultOverviewGetStaticProps, getStaticPathForPages } from '@/utils/pageUtils'

const AboutPage: React.FC<React.PropsWithChildren<InferGetStaticPropsType<typeof getStaticProps>>> = (props) => {
  const { id, slug, type } = props

  // TODO Typecheck: Generated by Next
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  if (type === 'about') return <About id={id} slug={slug} {...props} />

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  return <ContentPage id={id} slug={slug} {...props} />
}

export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: getStaticPathForPages(['', 'podcast']).paths,
    fallback: defaultGetStaticPathsFallback,
  }
}

export const getStaticProps: GetStaticProps = async (context) =>
  defaultOverviewGetStaticProps(
    'about',
    context,
    {
      query: GetAboutPageDocument,
      additionalProps: {
        type: 'about',
      },
    },
    {
      query: GetContentPageDocument,
      additionalProps: {
        type: 'contentPage',
      },
    },
    (data) => data.aboutPage == null,
    (data) => data.contentPage == null,
  )

export default AboutPage
